<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionEdit">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionEdit && !loading">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <router-link :to="{ name: 'Events' }">back to events</router-link>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <property-input
                :idProp="row.id"
                nameProp="name"
                nameDisplayProp="Event title"
                partitionProp="events"
                tableProp="events"
                :valueProp="row.name"
              />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
                <property-text-md
                :idProp="row.id"
                nameProp="comments"
                nameDisplayProp="Comments"
                partitionProp="events"
                tableProp="events"
                :valueProp="row.comments"
              />
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col>
              <edge-select-list
                v-if="!loading"
                directionProp="from vertex"
                edgeLabelProp="inRegtopic"
                :listSourceProp="{ partition: 'events', label: 'regtopic' }"
                nameProp="Regulatory topic"
                :vertexProp="row"
                />
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col>
              <h5>Delete</h5>
              <delete-vertex-soft :id="row.id" label="event" partition="events" @deleted="deleteAction" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DeleteVertexSoft from '@/components/DeleteVertexSoft.vue'
import EdgeSelectList from '@/components/cosmos/EdgeSelectList.vue'
import PropertyInput from '@/components/cosmos/PropertyInput.vue'
import PropertyTextMd from '@/components/cosmos/PropertyTextMd.vue'

export default {
  name: 'EventEdit',
  beforeDestroy () {},
  components: {
    DeleteVertexSoft,
    EdgeSelectList,
    PropertyInput,
    PropertyTextMd
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validateComments: function () {
      return this.edit.comments && this.row.comments
    },
    validateTitle: function () {
      return this.edit.name && this.row.name
    }
  },
  created: function () {
    // this.permissionEdit = ac.can(this.user.acgroups).createAny('event').granted
    this.permissionEdit = true
    this.load()
  },
  data () {
    return {
      edit: {
        comments: false,
        name: false
      },
      loading: true,
      permissionEdit: false,
      saving: false,
      row: {
        name: '',
        comments: ''
      },
      rowOld: {}
    }
  },
  methods: {
    deleteAction: function () {
      this.$router.push({ name: 'Events' })
    },
    load: async function () {
      this.$logger.debug('load started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/event/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.rowOld.name = this.row.name
        this.rowOld.comments = this.row.comments
        this.rowOld.link = this.row.link
        this.loading = false
      } catch (e) {
        this.$logger.warn('/event/:id error', e)
      }
    }
  }
}
</script>

<style>
</style>
